var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"noc-view-modal","title":"NOC View","centered":"","hide-footer":"","size":"xl","no-close-on-esc":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("NOC Details")])]},proxy:true}])},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-tabs',{attrs:{"content-class":"py-1","fill":""}},[_c('validation-observer',{ref:"nocEditValidation"},[_c('b-tab',{attrs:{"no-body":""}},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("CNIC")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"cnic","rules":"required|integer|positive|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnic","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"1234567890001"},model:{value:(_vm.cnic),callback:function ($$v) {_vm.cnic=$$v},expression:"cnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Name")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Contact No.")]),_c('validation-provider',{attrs:{"name":"Contact No","rules":"integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"contactNo","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Contact No"},model:{value:(_vm.contactNo),callback:function ($$v) {_vm.contactNo=$$v},expression:"contactNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"district"}},[_c('validation-provider',{attrs:{"name":"District","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("District")]),_c('v-select',{staticClass:"v-style-chooser",attrs:{"placeholder":"District","options":_vm.getDistricts,"label":"name","disabled":""},model:{value:(_vm.district),callback:function ($$v) {_vm.district=$$v},expression:"district"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Address")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false : null,"placeholder":"Address","disabled":""},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"isCommercial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('label',[_vm._v("Residential / Commercial")]),_c('b-form-radio-group',{attrs:{"id":"isCommercial","aria-describedby":ariaDescribedby,"disabled":""},model:{value:(_vm.isCommercial),callback:function ($$v) {_vm.isCommercial=$$v},expression:"isCommercial"}},[_c('b-form-radio',{attrs:{"value":false},model:{value:(_vm.isCommercial),callback:function ($$v) {_vm.isCommercial=$$v},expression:"isCommercial"}},[_vm._v(" Residential ")]),_c('b-form-radio',{attrs:{"value":true},model:{value:(_vm.isCommercial),callback:function ($$v) {_vm.isCommercial=$$v},expression:"isCommercial"}},[_vm._v(" Commercial ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("DHA")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"plot_society","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"v-style-chooser",attrs:{"options":_vm.getDHAs,"placeholder":"Society","label":"name","disabled":""},model:{value:(_vm.plot_society),callback:function ($$v) {_vm.plot_society=$$v},expression:"plot_society"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Plot Size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Plot Size")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('v-select',{staticClass:"v-style-chooser",attrs:{"options":_vm.getPlotSize,"placeholder":"Plot Size","label":"name","disabled":""},model:{value:(_vm.plot_size),callback:function ($$v) {_vm.plot_size=$$v},expression:"plot_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Plot No.")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":"plot_no","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"plot_no","placeholder":"Plot No.","disabled":""},model:{value:(_vm.plot_no),callback:function ($$v) {_vm.plot_no=$$v},expression:"plot_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Street")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('b-form-input',{attrs:{"id":"street","placeholder":"Street","disabled":""},model:{value:(_vm.street),callback:function ($$v) {_vm.street=$$v},expression:"street"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Sector")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('b-form-input',{attrs:{"id":"sector","placeholder":"Sector","disabled":""},model:{value:(_vm.sector),callback:function ($$v) {_vm.sector=$$v},expression:"sector"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Phase")]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('b-form-input',{attrs:{"id":"phase","placeholder":"Phase","disabled":""},model:{value:(_vm.phase),callback:function ($$v) {_vm.phase=$$v},expression:"phase"}})],1)],1)],1)],1)]),_c('b-form-group')],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }